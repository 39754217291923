import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
//
import { getSrcImageFixed } from '~storybook/image'

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
      }
    }
  }
`

const SEO = ({ title, description, metatags, globalImage, nid, gid, lang = 'en' }) => {
  const { site } = useStaticQuery(query)
  const { defaultTitle, titleTemplate, defaultDescription, siteUrl } = site.siteMetadata

  const parseValue = (key, value) => {
    if (key === 'image_src' || key === 'og:image' || key === 'twitter:image') {
      return getSrcImageFixed(value, { w: 1200, h: 600, r: 'fit' })
    }

    if (key === 'canonical') {
      const url = new URL(value)
      return `${siteUrl}${url.pathname}`
    }

    return value
  }

  const getMetatag = (key, value, __typename) => {
    if (__typename === 'MetaValue') {
      return <meta key={`meta-${key}`} name={key} content={parseValue(key, value)} />
    }
    if (__typename === 'MetaProperty') {
      return <meta key={`meta-${key}`} property={key} content={parseValue(key, value)} />
    }
    if (__typename === 'MetaLink') {
      return <link key={`meta-${key}`} rel={key} href={parseValue(key, value)} />
    }
    return null
  }

  const parsedTags = {}
  Object.values(metatags).forEach((tag) => {
    const { key, value, __typename } = tag

    const parsed = getMetatag(key, value, __typename)

    if (parsed) {
      parsedTags[key] = parsed
    }
  })

  if (!parsedTags?.description) {
    parsedTags.description = (
      <meta key="meta-description" name="description" content={description || defaultDescription || ''} />
    )
  }

  const globalImageUrl = getSrcImageFixed(globalImage, { w: 1200, h: 600, r: 'fit' })

  if (globalImageUrl) {
    if (!parsedTags?.image_src) {
      parsedTags.image_src = <link key="meta-image-src" rel="image_src" href={globalImageUrl} />
    }

    if (!parsedTags?.['twitter:image']) {
      parsedTags['twitter:image'] = <meta key="meta-twitter-image" name="twitter:image" content={globalImageUrl} />
    }

    if (!parsedTags?.['og:image']) {
      parsedTags['og:image'] = <meta key="meta-og-image" property="og:image" content={globalImageUrl} />
    }
  }

  if (nid) {
    parsedTags.nid = <meta key="meta-nid" name="page:nid" content={nid} />
  }

  if (gid) {
    parsedTags.gid = <meta key="meta-gid" name="page:gid" content={gid} />
  }

  const metaTitle = Object.values(metatags)?.find((meta) => meta?.key === 'title')?.[0]

  return (
    <Helmet
      title={metaTitle || title || defaultTitle}
      titleTemplate={titleTemplate}
      htmlAttributes={{
        lang
      }}
    >
      {Object.values(parsedTags)}
    </Helmet>
  )
}

export default SEO
