import React from 'react'
import { FormattedMessage } from 'react-intl'
//
import NavLink from '~utils/navlink'

export const LegalFooter = ({ links }) => {
  return (
    <div className="bg-white w-full flex relative mx-auto px-1 py-6">
      <div className="container max-w-7xl flex flex-row justify-center mx-auto">
        <div className="text-sm text-center">
          <span className="mx-2 text-c-footer-legalText text-sm">
            <FormattedMessage id="footer.chancelleryMessage" />
          </span>
          <div className="block lg:inline">
            {Array.isArray(links) &&
              links.map((link, idx) => (
                <NavLink
                  key={`legal-${link?.id}-${idx}`}
                  className="ml-1 mx-2 text-c-footer-legalText text-sm font-thin inline"
                  to={link?.url}
                >
                  {link?.label}
                </NavLink>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LegalFooter
